// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-event-services-js": () => import("./../../../src/pages/event-services.js" /* webpackChunkName: "component---src-pages-event-services-js" */),
  "component---src-pages-events-all-js": () => import("./../../../src/pages/events/all.js" /* webpackChunkName: "component---src-pages-events-all-js" */),
  "component---src-pages-events-athena-sit-down-social-js": () => import("./../../../src/pages/events/athena-sit-down-social.js" /* webpackChunkName: "component---src-pages-events-athena-sit-down-social-js" */),
  "component---src-pages-events-fresh-festival-js": () => import("./../../../src/pages/events/fresh-festival.js" /* webpackChunkName: "component---src-pages-events-fresh-festival-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-letsdisko-js": () => import("./../../../src/pages/events/letsdisko.js" /* webpackChunkName: "component---src-pages-events-letsdisko-js" */),
  "component---src-pages-events-student-race-day-js": () => import("./../../../src/pages/events/student-race-day.js" /* webpackChunkName: "component---src-pages-events-student-race-day-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-team-js": () => import("./../../../src/pages/join-the-team.js" /* webpackChunkName: "component---src-pages-join-the-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

